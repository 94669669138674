import { defineStore } from "pinia";
import type { Challenge, Payout, Report, User } from "~/gql/graphql";

export interface Moderator {
  user: Report[];
  challenge: Report[];
  challengeVideo: Report[];
  softBannedUsers: User[];
  hardBannedUsers: User[];
  refereeChallenges: Challenge[];
  payoutRequests: Payout[];
}

export const useModeratorStore = defineStore("moderator", () => {
  const createDefaults = () => ({
    user: [],
    challenge: [],
    challengeVideo: [],
    softBannedUsers: [],
    hardBannedUsers: [],
    refereeChallenges: [],
    payoutRequests: [],
  });

  const moderatorState = reactive<Moderator>(createDefaults());

  const flush = () => Object.assign(moderatorState, createDefaults());

  const update = (key: keyof Moderator, value: any): void => {
    moderatorState[key] = value;
  };

  return {
    ...toRefs(moderatorState),
    update,
    flush,
  };
});
