import type { Profile } from "~/gql/graphql";

export const useSettingsStore = defineStore("settingsPageStore", () => {
  const createDefaults = () =>
    <Partial<Profile>>{
      bio: "",
      displayName: "",
      avatar: "",
      challengeable: false,
      minimumChallengeEnabled: false,
      minimumChallengeValue: 0,
      hasPassword: false,
    };

  const settings = reactive<Profile>(createDefaults());

  const update = (newSettings: Partial<Profile>) =>
    Object.assign(settings, newSettings);

  const flush = () => Object.assign(settings, createDefaults());

  return {
    ...toRefs(settings),

    // Methods
    update,
    flush,
  };
});
