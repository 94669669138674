import { default as activity0buhMbNUJpMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/activity.vue?macro=true";
import { default as _91slug_93eKTyIz5V5pMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/challenge/[slug].vue?macro=true";
import { default as createzmm0HHnbLjMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/challenge/create.vue?macro=true";
import { default as email_45preferencesdIL58Q004wMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/email-preferences.vue?macro=true";
import { default as indexJ4r0o4eRNFMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/index.vue?macro=true";
import { default as accountz6edcvuYmAMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/account.vue?macro=true";
import { default as requestmIzUQzAc1BMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/magic-link/request.vue?macro=true";
import { default as requested5BvuHg3ryxMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/magic-link/requested.vue?macro=true";
import { default as verifyn9L7lbgiLsMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/magic-link/verify.vue?macro=true";
import { default as magic_45linkP71PQu1K9FMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/magic-link.vue?macro=true";
import { default as requestXJoUYalWQJMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/recover/request.vue?macro=true";
import { default as resetqbe5FG6ZOBMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/recover/reset.vue?macro=true";
import { default as recoverGRLMqKt6PVMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login/recover.vue?macro=true";
import { default as login3CJywj3WCWMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/login.vue?macro=true";
import { default as moderatorJpJeLA5eowMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/moderator.vue?macro=true";
import { default as successCukW5ZCNNLMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/payment/success.vue?macro=true";
import { default as active_45challengesrB7U2hbMK5Meta } from "/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/active-challenges.vue?macro=true";
import { default as completed_45challengesDqj0wxRYKKMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/completed-challenges.vue?macro=true";
import { default as respected_45videosULEb93DJESMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/respected-videos.vue?macro=true";
import { default as uploaded_45videoslbwBiJCozXMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/uploaded-videos.vue?macro=true";
import { default as _91username_93FirU4LnKhEMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/profile/[username].vue?macro=true";
import { default as change_45password7d5oMxGWbYMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings/change-password.vue?macro=true";
import { default as edit_45accountwJ9gameNKGMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-account.vue?macro=true";
import { default as edit_45paymentsDy7CbSrCleMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-payments.vue?macro=true";
import { default as edit_45settingsCSep3lCQQiMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-settings.vue?macro=true";
import { default as guidelines8VI55K2EouMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings/guidelines.vue?macro=true";
import { default as settingsRb4Rjo01RyMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/settings.vue?macro=true";
import { default as termsKUCZJjzS9LMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/terms.vue?macro=true";
import { default as PayoutModalRhOvEELHsgMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/wallet/PayoutModal.vue?macro=true";
import { default as walletJIhPwnhvnQMeta } from "/builds/Partout/sendchallenges/src/frontend/pages/wallet.vue?macro=true";
export default [
  {
    name: activity0buhMbNUJpMeta?.name ?? "activity",
    path: activity0buhMbNUJpMeta?.path ?? "/activity",
    meta: activity0buhMbNUJpMeta || {},
    alias: activity0buhMbNUJpMeta?.alias || [],
    redirect: activity0buhMbNUJpMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/activity.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eKTyIz5V5pMeta?.name ?? "challenge-slug",
    path: _91slug_93eKTyIz5V5pMeta?.path ?? "/challenge/:slug()",
    meta: _91slug_93eKTyIz5V5pMeta || {},
    alias: _91slug_93eKTyIz5V5pMeta?.alias || [],
    redirect: _91slug_93eKTyIz5V5pMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/challenge/[slug].vue").then(m => m.default || m)
  },
  {
    name: createzmm0HHnbLjMeta?.name ?? "challenge-create",
    path: createzmm0HHnbLjMeta?.path ?? "/challenge/create",
    meta: createzmm0HHnbLjMeta || {},
    alias: createzmm0HHnbLjMeta?.alias || [],
    redirect: createzmm0HHnbLjMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/challenge/create.vue").then(m => m.default || m)
  },
  {
    name: email_45preferencesdIL58Q004wMeta?.name ?? "email-preferences",
    path: email_45preferencesdIL58Q004wMeta?.path ?? "/email-preferences",
    meta: email_45preferencesdIL58Q004wMeta || {},
    alias: email_45preferencesdIL58Q004wMeta?.alias || [],
    redirect: email_45preferencesdIL58Q004wMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/email-preferences.vue").then(m => m.default || m)
  },
  {
    name: indexJ4r0o4eRNFMeta?.name ?? "index",
    path: indexJ4r0o4eRNFMeta?.path ?? "/",
    meta: indexJ4r0o4eRNFMeta || {},
    alias: indexJ4r0o4eRNFMeta?.alias || [],
    redirect: indexJ4r0o4eRNFMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login3CJywj3WCWMeta?.name ?? "login",
    path: login3CJywj3WCWMeta?.path ?? "/login",
    meta: login3CJywj3WCWMeta || {},
    alias: login3CJywj3WCWMeta?.alias || [],
    redirect: login3CJywj3WCWMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login.vue").then(m => m.default || m),
    children: [
  {
    name: accountz6edcvuYmAMeta?.name ?? "login-account",
    path: accountz6edcvuYmAMeta?.path ?? "account",
    meta: accountz6edcvuYmAMeta || {},
    alias: accountz6edcvuYmAMeta?.alias || [],
    redirect: accountz6edcvuYmAMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/account.vue").then(m => m.default || m)
  },
  {
    name: magic_45linkP71PQu1K9FMeta?.name ?? "login-magic-link",
    path: magic_45linkP71PQu1K9FMeta?.path ?? "magic-link",
    meta: magic_45linkP71PQu1K9FMeta || {},
    alias: magic_45linkP71PQu1K9FMeta?.alias || [],
    redirect: magic_45linkP71PQu1K9FMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/magic-link.vue").then(m => m.default || m),
    children: [
  {
    name: requestmIzUQzAc1BMeta?.name ?? "login-magic-link-request",
    path: requestmIzUQzAc1BMeta?.path ?? "request",
    meta: requestmIzUQzAc1BMeta || {},
    alias: requestmIzUQzAc1BMeta?.alias || [],
    redirect: requestmIzUQzAc1BMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/magic-link/request.vue").then(m => m.default || m)
  },
  {
    name: requested5BvuHg3ryxMeta?.name ?? "login-magic-link-requested",
    path: requested5BvuHg3ryxMeta?.path ?? "requested",
    meta: requested5BvuHg3ryxMeta || {},
    alias: requested5BvuHg3ryxMeta?.alias || [],
    redirect: requested5BvuHg3ryxMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/magic-link/requested.vue").then(m => m.default || m)
  },
  {
    name: verifyn9L7lbgiLsMeta?.name ?? "login-magic-link-verify",
    path: verifyn9L7lbgiLsMeta?.path ?? "verify",
    meta: verifyn9L7lbgiLsMeta || {},
    alias: verifyn9L7lbgiLsMeta?.alias || [],
    redirect: verifyn9L7lbgiLsMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/magic-link/verify.vue").then(m => m.default || m)
  }
]
  },
  {
    name: recoverGRLMqKt6PVMeta?.name ?? "login-recover",
    path: recoverGRLMqKt6PVMeta?.path ?? "recover",
    meta: recoverGRLMqKt6PVMeta || {},
    alias: recoverGRLMqKt6PVMeta?.alias || [],
    redirect: recoverGRLMqKt6PVMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/recover.vue").then(m => m.default || m),
    children: [
  {
    name: requestXJoUYalWQJMeta?.name ?? "login-recover-request",
    path: requestXJoUYalWQJMeta?.path ?? "request",
    meta: requestXJoUYalWQJMeta || {},
    alias: requestXJoUYalWQJMeta?.alias || [],
    redirect: requestXJoUYalWQJMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/recover/request.vue").then(m => m.default || m)
  },
  {
    name: resetqbe5FG6ZOBMeta?.name ?? "login-recover-reset",
    path: resetqbe5FG6ZOBMeta?.path ?? "reset",
    meta: resetqbe5FG6ZOBMeta || {},
    alias: resetqbe5FG6ZOBMeta?.alias || [],
    redirect: resetqbe5FG6ZOBMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/login/recover/reset.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: moderatorJpJeLA5eowMeta?.name ?? "moderator",
    path: moderatorJpJeLA5eowMeta?.path ?? "/moderator",
    meta: moderatorJpJeLA5eowMeta || {},
    alias: moderatorJpJeLA5eowMeta?.alias || [],
    redirect: moderatorJpJeLA5eowMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/moderator.vue").then(m => m.default || m)
  },
  {
    name: successCukW5ZCNNLMeta?.name ?? "payment-success",
    path: successCukW5ZCNNLMeta?.path ?? "/payment/success",
    meta: successCukW5ZCNNLMeta || {},
    alias: successCukW5ZCNNLMeta?.alias || [],
    redirect: successCukW5ZCNNLMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/payment/success.vue").then(m => m.default || m)
  },
  {
    name: _91username_93FirU4LnKhEMeta?.name ?? "profile-username",
    path: _91username_93FirU4LnKhEMeta?.path ?? "/profile/:username()",
    meta: _91username_93FirU4LnKhEMeta || {},
    alias: _91username_93FirU4LnKhEMeta?.alias || [],
    redirect: _91username_93FirU4LnKhEMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/profile/[username].vue").then(m => m.default || m),
    children: [
  {
    name: active_45challengesrB7U2hbMK5Meta?.name ?? "profile-username-active-challenges",
    path: active_45challengesrB7U2hbMK5Meta?.path ?? "active-challenges",
    meta: active_45challengesrB7U2hbMK5Meta || {},
    alias: active_45challengesrB7U2hbMK5Meta?.alias || [],
    redirect: active_45challengesrB7U2hbMK5Meta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/active-challenges.vue").then(m => m.default || m)
  },
  {
    name: completed_45challengesDqj0wxRYKKMeta?.name ?? "profile-username-completed-challenges",
    path: completed_45challengesDqj0wxRYKKMeta?.path ?? "completed-challenges",
    meta: completed_45challengesDqj0wxRYKKMeta || {},
    alias: completed_45challengesDqj0wxRYKKMeta?.alias || [],
    redirect: completed_45challengesDqj0wxRYKKMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/completed-challenges.vue").then(m => m.default || m)
  },
  {
    name: respected_45videosULEb93DJESMeta?.name ?? "profile-username-respected-videos",
    path: respected_45videosULEb93DJESMeta?.path ?? "respected-videos",
    meta: respected_45videosULEb93DJESMeta || {},
    alias: respected_45videosULEb93DJESMeta?.alias || [],
    redirect: respected_45videosULEb93DJESMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/respected-videos.vue").then(m => m.default || m)
  },
  {
    name: uploaded_45videoslbwBiJCozXMeta?.name ?? "profile-username-uploaded-videos",
    path: uploaded_45videoslbwBiJCozXMeta?.path ?? "uploaded-videos",
    meta: uploaded_45videoslbwBiJCozXMeta || {},
    alias: uploaded_45videoslbwBiJCozXMeta?.alias || [],
    redirect: uploaded_45videoslbwBiJCozXMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/profile/[username]/uploaded-videos.vue").then(m => m.default || m)
  }
]
  },
  {
    name: settingsRb4Rjo01RyMeta?.name ?? "settings",
    path: settingsRb4Rjo01RyMeta?.path ?? "/settings",
    meta: settingsRb4Rjo01RyMeta || {},
    alias: settingsRb4Rjo01RyMeta?.alias || [],
    redirect: settingsRb4Rjo01RyMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: change_45password7d5oMxGWbYMeta?.name ?? "settings-change-password",
    path: change_45password7d5oMxGWbYMeta?.path ?? "change-password",
    meta: change_45password7d5oMxGWbYMeta || {},
    alias: change_45password7d5oMxGWbYMeta?.alias || [],
    redirect: change_45password7d5oMxGWbYMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings/change-password.vue").then(m => m.default || m)
  },
  {
    name: edit_45accountwJ9gameNKGMeta?.name ?? "settings-edit-account",
    path: edit_45accountwJ9gameNKGMeta?.path ?? "edit-account",
    meta: edit_45accountwJ9gameNKGMeta || {},
    alias: edit_45accountwJ9gameNKGMeta?.alias || [],
    redirect: edit_45accountwJ9gameNKGMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-account.vue").then(m => m.default || m)
  },
  {
    name: edit_45paymentsDy7CbSrCleMeta?.name ?? "settings-edit-payments",
    path: edit_45paymentsDy7CbSrCleMeta?.path ?? "edit-payments",
    meta: edit_45paymentsDy7CbSrCleMeta || {},
    alias: edit_45paymentsDy7CbSrCleMeta?.alias || [],
    redirect: edit_45paymentsDy7CbSrCleMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-payments.vue").then(m => m.default || m)
  },
  {
    name: edit_45settingsCSep3lCQQiMeta?.name ?? "settings-edit-settings",
    path: edit_45settingsCSep3lCQQiMeta?.path ?? "edit-settings",
    meta: edit_45settingsCSep3lCQQiMeta || {},
    alias: edit_45settingsCSep3lCQQiMeta?.alias || [],
    redirect: edit_45settingsCSep3lCQQiMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings/edit-settings.vue").then(m => m.default || m)
  },
  {
    name: guidelines8VI55K2EouMeta?.name ?? "settings-guidelines",
    path: guidelines8VI55K2EouMeta?.path ?? "guidelines",
    meta: guidelines8VI55K2EouMeta || {},
    alias: guidelines8VI55K2EouMeta?.alias || [],
    redirect: guidelines8VI55K2EouMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/settings/guidelines.vue").then(m => m.default || m)
  }
]
  },
  {
    name: termsKUCZJjzS9LMeta?.name ?? "terms",
    path: termsKUCZJjzS9LMeta?.path ?? "/terms",
    meta: termsKUCZJjzS9LMeta || {},
    alias: termsKUCZJjzS9LMeta?.alias || [],
    redirect: termsKUCZJjzS9LMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: walletJIhPwnhvnQMeta?.name ?? "wallet",
    path: walletJIhPwnhvnQMeta?.path ?? "/wallet",
    meta: walletJIhPwnhvnQMeta || {},
    alias: walletJIhPwnhvnQMeta?.alias || [],
    redirect: walletJIhPwnhvnQMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/wallet.vue").then(m => m.default || m),
    children: [
  {
    name: PayoutModalRhOvEELHsgMeta?.name ?? "wallet-PayoutModal",
    path: PayoutModalRhOvEELHsgMeta?.path ?? "PayoutModal",
    meta: PayoutModalRhOvEELHsgMeta || {},
    alias: PayoutModalRhOvEELHsgMeta?.alias || [],
    redirect: PayoutModalRhOvEELHsgMeta?.redirect,
    component: () => import("/builds/Partout/sendchallenges/src/frontend/pages/wallet/PayoutModal.vue").then(m => m.default || m)
  }
]
  }
]