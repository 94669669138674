<template>
  <ul class="text-grey leading-[3rem] pb-5">
    <li>
      <NuxtLink to="/challenge/create">
        <FwbButton :color="challengeButtonActive" class="p-1">
          <div class="flex items-center gap-2">
            <SendIcon
              name="lightning_bolt"
              class="h-auto max-w-full text-2xl"
            />

            {{ $t("sidebar_start_a_challenge") }}
          </div>
        </FwbButton>
      </NuxtLink>
    </li>
  </ul>
  <div class="bg-gradient-to-br from-blue-600 to-violet-600 p-4 rounded-xl">
    <p class="text-white text-sm leading-loose mb-3">
      {{ $t("sidebar_login_text") }}
    </p>
    <NuxtLink to="/login/magic-link/request">
      <FwbButton color="white" class="ml-1">
        <div class="flex items-center gap-2">
          {{ $t("sidebar_login_button") }}
          <SendIcon name="Profile" class="h-auto max-w-full" />
        </div>
      </FwbButton>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import SendIcon from "~/components/partials/SendIcon.vue";
import FwbButton from "~/components/Flowbite/FwbButton/FwbButton.vue";

const route = useRoute();

const challengeButtonActive = computed(() =>
  typeof route.name === "string" && route.name.includes("challenge-create")
    ? "purple"
    : "white"
);
</script>
