<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const { locale } = toRefs(useUserStore());
const lang = computed(() => locale.value);

useHead({
  title: "SendChallenges | Send Challenges to your friends or enemies",
  meta: [
    {
      name: "description",
      content: "Create a challenge and let's see who is the best!",
    },
  ],
  htmlAttrs: {
    lang: lang.value,
  },
});

watch(lang, (newLang) => {
  useHead({
    htmlAttrs: {
      lang: newLang,
    },
  });
});
</script>
