<template>
  <component :is="componentTag" v-bind="$attrs" :class="headingClasses">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import { useAttrs } from "vue";

defineOptions({
  inheritAttrs: false,
});

interface HeadingProps {
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  color?: string;
  customSize?: string;
}

const props = withDefaults(defineProps<HeadingProps>(), {
  tag: "h1",
  color: "text-send-grey",
  customSize: "",
});

const textSizes: Record<string, string> = {
  h1: "sc-text-header-h1",
  h2: "sc-text-header-h2",
  h3: "sc-text-header-h3",
  h4: "sc-text-header-h4",
  h5: "sc-text-header-h5",
  h6: "sc-text-header-h6",
};
const attrs = useAttrs();

const headingClasses = twMerge(
  "w-full",
  textSizes[props.tag],
  props.color,
  props.customSize,
  attrs.class as string
);

const componentTag = props.tag;
</script>
