<template>
  <FwbModal v-if="showModal" persistent size="4xl">
    <template #header>
      <FwbHeading tag="h5" class="text-center">
        {{ t("log_in_to_sendchallenges") }}
      </FwbHeading>
    </template>
    <template #body>
      <div class="flex flex-col gap-4 mb-4">
        <FwbP>
          <span class="text-sm font-bold">{{ t("email_address") }}</span>
          <br />{{ verifyingUser.email }}
        </FwbP>
        <FwbInput
          v-model="v.username.$model"
          :label="t('account.username')"
          :placeholder="t('username')"
        />
        <FwbToggle
          v-model="v.generatePassword.$model"
          :label="t('account.create_password')"
        />
        <FwbToggle
          v-model="v.eighteenPlusContent.$model"
          :label="t('account.age_check')"
        />
      </div>
    </template>
    <template #footer>
      <FwbButton color="dark" :disabled="v.$invalid" @click="onSubmit">
        {{ t("log_in") }}
      </FwbButton>
    </template>
  </FwbModal>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import FwbP from "~/components/Flowbite/Typography/FwbP.vue";
import { useI18n } from "vue-i18n";
import FwbButton from "~/components/Flowbite/FwbButton/FwbButton.vue";
import FwbInput from "~/components/Flowbite/FwbInput/FwbInput.vue";
import FwbModal from "~/components/Flowbite/FwbModal/FwbModal.vue";
import FwbToggle from "~/components/Flowbite/FwbToggle/FwbToggle.vue";
import FwbHeading from "~/components/Flowbite/Typography/FwbHeading.vue";

const { t } = useI18n();

defineProps<{
  showModal: boolean | Ref<boolean>;
}>();

const { verifyUserMutation } = useAuthStore();
const { verifyingUser } = toRefs(useAuthStore());

const emit = defineEmits<{
  (event: "update:showModal", value: boolean): void;
}>();

const form = reactive({
  eighteenPlusContent: false,
  generatePassword: false,
  username: "",
});

const v = useVuelidate(
  {
    username: { required },
    eighteenPlusContent: { required },
    generatePassword: { required },
  },
  form
);

const onSubmit = () => {
  emit("update:showModal", false);
  const verifyValues = {
    ...(verifyingUser.value.type === "code" && {
      code: verifyingUser.value.code,
    }),
    ...(verifyingUser.value.type === "link" && {
      hash: verifyingUser.value.hash,
      expires: +verifyingUser.value.expires,
    }),
  };

  verifyUserMutation.mutate({
    input: {
      email: verifyingUser.value.email,
      ...verifyValues,
      ...form,
    },
  });
};

verifyUserMutation.onDone(() => {
  navigateTo("/challenge/create");
});
</script>
