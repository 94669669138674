import validate from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.3_eslint@8.57.0_flo_puc3gphbpgiohbvmvst6li6sje/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.3_eslint@8.57.0_flo_puc3gphbpgiohbvmvst6li6sje/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "logged-in": () => import("/builds/Partout/sendchallenges/src/frontend/middleware/logged-in.ts"),
  "not-logged-in": () => import("/builds/Partout/sendchallenges/src/frontend/middleware/not-logged-in.ts"),
  "user-is-admin": () => import("/builds/Partout/sendchallenges/src/frontend/middleware/user-is-admin.ts"),
  "user-is-owner": () => import("/builds/Partout/sendchallenges/src/frontend/middleware/user-is-owner.ts")
}