<template>
  <div class="relative h-screen lg:flex">
    <div
      class="p-2 shadow flex justify-between items-center bg-send-grey-nav lg:flex-col lg:justify-normal lg:items-stretch lg:py-12 lg:px-10 lg:w-80 lg:min-w-80 lg:gap-16"
    >
      <Logo />
      <SidebarMenu class="hidden lg:block" />
      <MobileMenu class="lg:hidden" />
    </div>
    <div
      class="relative w-full h-[calc(100vh-74px)] lg:h-screen overflow-y-auto flex flex-col bg-send-grey-bg"
    >
      <slot />
    </div>

    <div class="fixed right-4 bottom-4">
      <NuxtLink
        to="/challenge/create"
        class="flex items-center justify-center bg-gradient-to-br from-blue-600 to-violet-600 rounded-[30px] w-16 h-16 hover:bg-gradient-to-r"
      >
        <img
          class="h-8"
          src="/assets/images/lightning-bolt-with-shadow.svg"
          alt="lightning icon"
        />
      </NuxtLink>
    </div>

    <CompleteAccountModal v-model:showModal="showModal" />
  </div>
</template>

<script setup lang="ts">
import CompleteAccountModal from "~/components/auth/CompleteAccountModal.vue";
import MobileMenu from "~/components/navigation/MobileMenu.vue";
import SidebarMenu from "~/components/navigation/SidebarMenu.vue";
import Logo from "~/components/partials/logo.vue";

const authStore = useAuthStore();
const { verifyingUser } = toRefs(authStore);
const showModal = computed(() => !!verifyingUser.value.type);
</script>
