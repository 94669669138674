import { computed, type Ref } from "vue";
import { twMerge } from "tailwind-merge";
import {
  type InputSize,
  type ValidationStatus,
  validationStatusMap,
} from "../types";

// LABEL
const baseLabelClasses = "block mb-2 text-send-grey text-sm font-medium";

// INPUT
const defaultInputClasses =
  "bg-gray-50 border border-send-grey-80 text-send-grey text-sm rounded-lg block w-full";
const disabledInputClasses = "cursor-not-allowed bg-gray-100";
const inputSizeClasses: Record<InputSize, string> = {
  xl: "p-6 text-2xl",
  lg: "p-4 text-lg",
  md: "p-2.5 text-md",
  sm: "p-2 text-sm",
};

const successInputClasses =
  "bg-green-50 border-green-500 text-green-900 placeholder-green-700 focus:ring-green-500 focus:border-green-500";
const errorInputClasses =
  "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500";

export type UseInputClassesProps = {
  size: Ref<InputSize>;
  disabled: Ref<boolean>;
  validationStatus: Ref<ValidationStatus | undefined>;
};

export function useInputClasses(props: UseInputClassesProps): {
  inputClasses: Ref<string>;
  labelClasses: Ref<string>;
} {
  const inputClasses = computed(() => {
    const vs = props.validationStatus.value;

    const classByStatus =
      vs === validationStatusMap.Success
        ? successInputClasses
        : vs === validationStatusMap.Error
          ? errorInputClasses
          : "";

    return twMerge(
      defaultInputClasses,
      classByStatus,
      inputSizeClasses[props.size.value],
      props.disabled.value ? disabledInputClasses : ""
    );
  });

  const labelClasses = computed(() => {
    const vs = props.validationStatus.value;
    const classByStatus =
      vs === validationStatusMap.Success
        ? "text-green-700"
        : vs === validationStatusMap.Error
          ? "text-red-700"
          : "text-gray-900";

    return twMerge(baseLabelClasses, classByStatus);
  });

  return {
    inputClasses,
    labelClasses,
  };
}
