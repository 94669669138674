<template>
  <div class="relative">
    <div :class="avatarPlaceholderWrapperClasses">
      <div
        v-if="loading"
        class="animate-pulse bg-gray-200 w-full h-full absolute"
        :class="avatarClasses"
      />
      <img
        v-else-if="img && !imageError"
        :alt="alt"
        :class="avatarClasses"
        :src="img"
        @error="setImageError"
      />
      <div
        v-else-if="!initials && hasPlaceholder"
        :class="avatarPlaceholderClasses"
      >
        <slot name="placeholder" />
      </div>
      <svg
        v-else-if="!img && !initials"
        :class="avatarPlaceholderClasses"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clip-rule="evenodd"
          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
          fill-rule="evenodd"
        />
      </svg>
      <div v-else :class="avatarPlaceholderInitialsClasses">
        {{ initials }}
      </div>
    </div>
    <span v-if="status" :class="avatarDotClasses" :data-pos="statusPosition" />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, toRefs, useSlots } from "vue";
import type { AvatarSize, AvatarStatus, AvatarStatusPosition } from "./types";
import { useAvatarClasses } from "~/components/Flowbite/FwbAvatar/composables/useAvatarClasses";

const imageError = ref(false);

function setImageError() {
  imageError.value = true;
}

const slots = useSlots();
const hasPlaceholder = computed(() => slots.placeholder);

interface Props {
  img?: string;
  alt?: string;
  avatarClasses?: string;
  bordered?: boolean;
  initials?: string;
  rounded?: boolean;
  size?: AvatarSize;
  stacked?: boolean;
  status?: AvatarStatus | null;
  statusPosition?: AvatarStatusPosition;
  loading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  alt: "Avatar",
  bordered: false,
  img: "",
  initials: "",
  rounded: false,
  size: "md",
  stacked: false,
  status: null,
  statusPosition: "top-right",
  loading: false,
  avatarClasses: undefined,
});

const {
  avatarClasses,
  avatarDotClasses,
  avatarPlaceholderClasses,
  avatarPlaceholderInitialsClasses,
  avatarPlaceholderWrapperClasses,
} = useAvatarClasses(toRefs(props));
</script>
