<template>
  <div
    v-if="props.showMenu"
    class="fixed top-[74px] left-0 h-[calc(100vh-74px)] p-4 w-screen bg-white z-50 lg:hidden"
    @close="emit('update:showMenu', false)"
  >
    <LoggedInMenu v-if="loggedIn" />
    <NotLoggedInMenu v-else />
  </div>
</template>
<script setup lang="ts">
import { useAuthStore } from "~/stores/auth";
import LoggedInMenu from "~/components/navigation/LoggedInMenu.vue";
import NotLoggedInMenu from "~/components/navigation/NotLoggedInMenu.vue";

const props = defineProps<{
  showMenu: boolean;
}>();

const userStore = useAuthStore();
const { loggedIn } = toRefs(userStore);

const emit = defineEmits(["update:showMenu"]);
</script>
