<template>
  <FwbButton color="transparent" @click="userLogout">
    <div class="flex items-center gap-2">
      <SendIcon name="Login" />
      <span v-if="logout.loading">Logging out...</span>
      <span v-else>
        {{ $t("logout") }}
      </span>
    </div>
  </FwbButton>
</template>

<script setup lang="ts">
import FwbButton from "~/components/Flowbite/FwbButton/FwbButton.vue";
import SendIcon from "~/components/partials/SendIcon.vue";
import { useAuthStore } from "~/stores/auth";

const { logout } = useAuthStore();
const userLogout = () => {
  if (logout.loading) return;
  logout.mutate();
};

logout.onDone(() => {
  useFlushAndRedirect();
});
</script>
