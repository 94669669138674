<template>
  <div class="h-full">
    <LoggedInMenu v-if="loggedIn" />
    <NotLoggedInMenu v-else />
  </div>
</template>

<script setup lang="ts">
import LoggedInMenu from "~/components/navigation/LoggedInMenu.vue";
import NotLoggedInMenu from "~/components/navigation/NotLoggedInMenu.vue";
import { useAuthStore } from "~/stores/auth";

const userStore = useAuthStore();
const { loggedIn } = toRefs(userStore);
</script>
