import revive_payload_client_7pQLjSC14N from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.3_eslint@8.57.0_flo_puc3gphbpgiohbvmvst6li6sje/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ClknDXQasf from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.3_eslint@8.57.0_flo_puc3gphbpgiohbvmvst6li6sje/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RCbbzpftrC from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.3_eslint@8.57.0_flo_puc3gphbpgiohbvmvst6li6sje/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_jlBlAak9Em from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.3_eslint@8.57.0_flo_puc3gphbpgiohbvmvst6li6sje/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_GazOmC1J1M from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.3_eslint@8.57.0_flo_puc3gphbpgiohbvmvst6li6sje/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_6kn2MtOPQ4 from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.5_vue@3.4.22/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/Partout/sendchallenges/src/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_2nvC2hFpHh from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.3_eslint@8.57.0_flo_puc3gphbpgiohbvmvst6li6sje/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_3PcGoBL7Xp from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@18.19.31_@unocss+reset@0.59.3_eslint@8.57.0_flo_puc3gphbpgiohbvmvst6li6sje/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_9RNjyLX3El from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import apollo_1ILFkzpYSr from "/builds/Partout/sendchallenges/src/frontend/plugins/apollo.ts";
import i18n_VfGcjrvSkj from "/builds/Partout/sendchallenges/src/frontend/plugins/i18n.ts";
import sentry_3AyO8nEfhE from "/builds/Partout/sendchallenges/src/frontend/plugins/sentry.ts";
import toast_ysMjUcU7eJ from "/builds/Partout/sendchallenges/src/frontend/plugins/toast.ts";
export default [
  revive_payload_client_7pQLjSC14N,
  unhead_ClknDXQasf,
  router_RCbbzpftrC,
  payload_client_jlBlAak9Em,
  check_outdated_build_client_GazOmC1J1M,
  plugin_vue3_6kn2MtOPQ4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2nvC2hFpHh,
  chunk_reload_client_3PcGoBL7Xp,
  plugin_9RNjyLX3El,
  apollo_1ILFkzpYSr,
  i18n_VfGcjrvSkj,
  sentry_3AyO8nEfhE,
  toast_ysMjUcU7eJ
]