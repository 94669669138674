<template>
  <i class="icon" :class="[`icon-${name}`, `text-${computedSize}`]" />
</template>

<script setup lang="ts">
import type { IconsId } from "~/assets/css/icons/icons";
import type { ButtonSize } from "../Flowbite/FwbButton/types";

const props = defineProps<{
  name: IconsId;
  size?: ButtonSize;
}>();

const computedSize = computed(() => {
  return props.size ? props.size : "xl";
});
</script>
