import { init, addIntegration, browserTracingIntegration } from "@sentry/vue";

async function lazyLoadSentryIntegrations() {
  // don't load on server
  if (!process.client) return;

  const { replayIntegration } = await import("@sentry/vue");

  addIntegration(
    replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    })
  );
}

function getSentryIntegrations() {
  // don't load on server
  if (!process.client) return [];
  const router = useRouter();
  const browserTracing = browserTracingIntegration({
    router,
  });
  return [browserTracing];
}

export default defineNuxtPlugin({
  name: "sentry",
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;
    const {
      public: {
        SENTRY_DSN,
        SENTRY_ENVIRONMENT,
        SENTRY_TRACES_SAMPLE_RATE,
        SENTRY_REPLAY_SAMPLE_RATE,
        SENTRY_ERROR_REPLAY_SAMPLE_RATE,
      },
    } = useRuntimeConfig();

    init({
      app: vueApp,
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT,
      integrations: getSentryIntegrations(),

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE as number,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        "https://sendchallenges.partout.dev",
        "https://sendchallenges.com",
      ],

      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: SENTRY_REPLAY_SAMPLE_RATE as number,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: SENTRY_ERROR_REPLAY_SAMPLE_RATE as number,
    });

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations();
  },
});
