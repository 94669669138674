import { defineStore } from "pinia";
import type { ProfilePaymentInformation } from "~/gql/graphql";

export const usePaymentPageStore = defineStore(
  "paymentStore",
  () => {
    const createDefaults = () =>
      <Partial<ProfilePaymentInformation>>{
        paymentAccountNumber: "",
        paymentAddress: "",
        paymentBic: "",
        paymentCity: "",
        paymentName: "",
        paymentPostalCode: "",
      };

    const state = reactive(createDefaults());

    const update = (newState: Partial<ProfilePaymentInformation>) =>
      Object.assign(state, newState);

    const flush = () => Object.assign(state, createDefaults());

    return {
      ...toRefs(state),

      // Methods
      update,
      flush,
    };
  },
  {
    persist: true,
  }
);
