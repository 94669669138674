<template>
  <img
    class="md:hidden w-10 h-auto md:h-10"
    src="/assets/images/logo_sm.svg"
    alt="SendChallenges"
  />
  <img
    class="hidden md:block lg:hidden w-10 md:w-32 lg:w-96 h-auto"
    src="/assets/images/logo_md.svg"
    alt="SendChallenges"
  />
  <img
    class="hidden lg:block w-10 md:w-32 lg:w-[259px] h-auto"
    src="/assets/images/logo_lg.svg"
    alt="SendChallenges"
  />
</template>

<script setup lang="ts"></script>
