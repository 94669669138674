<template>
  <div v-bind="$attrs" class="flex items-center gap-2">
    <div class="flex-grow flex items-center justify-end">
      <FwbButton type="button" color="transparent-border" @click="toggleMenu">
        <div class="flex items-center">
          <img
            class="h-auto max-w-full"
            src="/assets/images/hamburger.svg"
            alt="hamburger icon"
          />
          <span class="ml-2">
            {{ $t("menu") }}
          </span>
        </div>
      </FwbButton>
    </div>
    <FwbAvatar
      rounded
      status-position="bottom-right"
      status="online"
      class="rounded-full border border-black"
      :img="avatar ?? undefined"
      :initials="displayName?.[0] ?? 'A'"
    />
  </div>
  <MenuModal v-model:showMenu="showMenu" />
</template>

<script setup lang="ts">
import FwbButton from "~/components/Flowbite/FwbButton/FwbButton.vue";
import FwbAvatar from "~/components/Flowbite/FwbAvatar/FwbAvatar.vue";
import MenuModal from "~/components/navigation/MenuModal.vue";

const nuxt = useNuxtApp();
const showMenu = ref(false);

const { userStore } = useUser();
const { avatar, displayName } = toRefs(userStore);

nuxt.hooks.hook("page:start", () => {
  showMenu.value = false;
});

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};
</script>
