import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  type ServerParseError,
} from "@apollo/client/core";

import { createUploadLink } from "apollo-upload-client";

import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import {
  DefaultApolloClient,
  provideApolloClients,
} from "@vue/apollo-composable";
import { logErrorMessages } from "@vue/apollo-util";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const uri = config.public.GRAPHQL_ENDPOINT ?? "";

  const errorLink = onError((error) => {
    if ((error.networkError as ServerParseError)?.statusCode === 401) {
      useFlushAndRedirect();
    }
    if (process.env.NODE_ENV !== "production") {
      logErrorMessages(error);
    }
  });

  const authLink = setContext((_, context) => {
    const { headers } = context;
    const token = useCookie("Bearer");
    const hasAuthorizationHeader = headers && "Authorization" in headers;

    // return the headers to the context so httpLink can read them
    return {
      ...context,
      headers: {
        ...headers,
        ...(token?.value
          ? {
              // Make it possible to overwrite the Authorization header per request
              [`Authorization`]: hasAuthorizationHeader
                ? headers.Authorization
                : `Bearer ${token.value}`,
            }
          : {}),
      },
    };
  });

  // HTTP connection to the API
  const httpLink = createHttpLink({
    uri,
  });

  const uploadLink = createUploadLink({
    uri,
  });

  // Cache implementation
  const cache = new InMemoryCache();

  // Create the apollo client
  const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
  });

  apolloClient.setLink(from([errorLink, authLink, uploadLink]));

  provideApolloClients({
    default: apolloClient,
  });

  nuxtApp.provide(String(DefaultApolloClient), apolloClient);
});
