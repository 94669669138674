<template>
  <div class="flex flex-col gap-4 lg:justify-between lg:h-full">
    <div>
      <NuxtLink class="block max-h-max" to="/challenge/create">
        <FwbButton
          :color="
            route.name === 'challenge-create'
              ? 'transparent-purple'
              : 'transparent-selected'
          "
        >
          <div class="flex items-center">
            <SendIcon name="lightning_bolt" />
            <span class="ml-2">
              {{ $t("sidebar_start_a_challenge") }}
            </span>
          </div>
        </FwbButton>
      </NuxtLink>

      <ul class="text-grey flex flex-col lg:mt-4">
        <li>
          <NuxtLink
            class="block max-h-max"
            :to="`/profile/${username}/active-challenges`"
          >
            <FwbButton
              :color="
                typeof route.name === 'string' && route.name.includes('profile')
                  ? 'purple'
                  : 'transparent'
              "
            >
              <div class="flex items-center">
                <SendIcon name="Profile" />
                <span class="ml-2">
                  {{ $t("sidebar_my_profile") }}
                </span>
              </div>
            </FwbButton>
          </NuxtLink>
        </li>
        <li>
          <NuxtLink class="block max-h-max" to="/activity">
            <FwbButton
              :color="route.name === 'activity' ? 'purple' : 'transparent'"
            >
              <div class="flex items-center">
                <SendIcon name="Dashboard" />
                <span class="ml-2">
                  {{ $t("sidebar_my_dashboard") }}
                </span>
              </div>
            </FwbButton>
          </NuxtLink>
        </li>
        <li>
          <NuxtLink class="block max-h-max" to="/wallet">
            <FwbButton
              :color="route.name === 'wallet' ? 'purple' : 'transparent'"
            >
              <div class="flex items-center">
                <SendIcon name="Wallet" />
                <span class="ml-2">
                  {{ $t("wallet") }}
                </span>
              </div>
            </FwbButton>
          </NuxtLink>
        </li>
      </ul>
      <ul class="text-grey leading-[3rem] lg:mt-12">
        <li>
          <NuxtLink
            :to="`/profile/${username}/active-challenges`"
            class="flex items-center bg-gradient-to-br from-blue-600 to-violet-600 p-2 px-4 gap-4 rounded-xl"
          >
            <FwbAvatar
              status-position="bottom-right"
              status="online"
              :img="avatar ?? ''"
              :initials="displayName?.[0] ?? 'A'"
            />
            <FwbP class="text-white" truncate>
              {{ displayName }}
            </FwbP>
          </NuxtLink>
        </li>
      </ul>
    </div>

    <ul class="flex flex-col gap-2">
      <li>
        <NuxtLink class="block max-h-max" to="/settings/edit-settings">
          <FwbButton
            :color="
              typeof route.name === 'string' && route.name.includes('settings')
                ? 'purple'
                : 'transparent'
            "
          >
            <div class="flex items-center">
              <SendIcon name="Preferences" />
              <span class="ml-2">
                {{ $t("settings") }}
              </span>
            </div>
          </FwbButton>
        </NuxtLink>
      </li>
      <li>
        <LogoutButton />
      </li>
      <li>
        <LanguageDropdown class="mt-2" />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "~/stores/user";

import LogoutButton from "~/components/auth/LogoutButton.vue";
import FwbAvatar from "~/components/Flowbite/FwbAvatar/FwbAvatar.vue";
import FwbButton from "~/components/Flowbite/FwbButton/FwbButton.vue";
import FwbP from "~/components/Flowbite/Typography/FwbP.vue";
import LanguageDropdown from "~/components/partials/LanguageDropdown.vue";
import SendIcon from "~/components/partials/SendIcon.vue";

const userStore = useUserStore();
const route = useRoute();
const { username, displayName, avatar } = toRefs(userStore);
</script>
