<template>
  <FwbDropdown :text="locale.toUpperCase()" placement="top">
    <FwbListGroup>
      <FwbListGroupItem
        class="cursor-pointer hover:bg-send-grey-bg"
        @click="changeLanguage('nl')"
      >
        NL
      </FwbListGroupItem>
      <FwbListGroupItem
        class="cursor-pointer hover:bg-send-grey-bg"
        @click="changeLanguage('en')"
      >
        EN
      </FwbListGroupItem>
    </FwbListGroup>
  </FwbDropdown>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import FwbDropdown from "~/components/Flowbite/FwbDropdown/FwbDropdown.vue";
import FwbListGroup from "~/components/Flowbite/FwbListGroup/FwbListGroup.vue";
import FwbListGroupItem from "~/components/Flowbite/FwbListGroup/FwbListGroupItem.vue";

const { locale } = toRefs(useUserStore());
const i18n = useI18n();

const changeLanguage = (lang: Locale) => {
  locale.value = lang;
  i18n.locale.value = lang;
};
</script>
