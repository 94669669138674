import type { EmailPreferences } from "~/gql/graphql";

export const useEmailPreferencesStore = defineStore(
  "emailPreferencesStore",
  () => {
    const createDefaults = () =>
      <EmailPreferences>{
        completedChallenge: null,
        newChallenge: null,
        updateChallenge: null,
        marketing: null,
      };

    const preferences = reactive<EmailPreferences>(createDefaults());

    const update = (newPreferences: Partial<EmailPreferences>) =>
      Object.assign(preferences, newPreferences);

    const flush = () => Object.assign(preferences, createDefaults());

    return {
      ...toRefs(preferences),

      // Methods
      update,
      flush,
    };
  }
);
