<template>
  <div :class="['font-send-default', truncate ? 'truncate' : '']">
    <p
      :class="[
        color,
        sizes[size],
        // heights[height],
        weights[weight],
        whitespaces[whitespace],
        aligns[align],
        truncate ? 'truncate' : '',
      ]"
    >
      <slot />
    </p>
  </div>
</template>

<script setup lang="ts">
interface ParagraphProps {
  // height?: "normal" | "relaxed" | "loose";
  color?: string;
  size?: string;
  weight?: string;
  whitespace?: string;
  align?: string;
  truncate?: boolean;
}

withDefaults(defineProps<ParagraphProps>(), {
  // height: "normal",
  color: "",
  size: "",
  weight: "",
  whitespace: "",
  align: "",
  truncate: false,
});

const sizes: Record<string, string> = {
  xs: "sc-text-body-xs",
  sm: "sc-text-body-sm",
  base: "sc-text-body-base",
  lg: "sc-text-body-lg",
  xl: "sc-text-body-xl",
  "2xl": "sc-text-body-2xl",
  "3xl": "sc-text-body-3xl",
};

const weights: Record<string, string> = {
  thin: "font-thin",
  extralight: "font-extralight",
  light: "font-light",
  normal: "font-normal",
  medium: "font-medium",
  semibold: "font-semibold",
  bold: "font-bold",
  extrabold: "font-extrabold",
  black: "font-black",
};

const aligns: Record<string, string> = {
  left: "text-left",
  center: "text-center",
  right: "text-right",
};

// const heights: Record<string, string> = {
//   normal: "leading-normal",
//   relaxed: "leading-relaxed",
//   loose: "leading-loose",
// };

const whitespaces: Record<string, string> = {
  normal: "whitespace-normal",
  nowrap: "whitespace-nowrap",
  pre: "whitespace-pre",
  preline: "whitespace-pre-line",
  prewrap: "whitespace-pre-wrap",
};
</script>
