import type { ApolloError, Context } from "@apollo/client";
import { useMutation } from "@vue/apollo-composable";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import useUser from "~/composables/useUser";
import { graphql } from "~/gql";
import type { Status } from "~/gql/graphql";
import { verifyUser } from "~/graphql/user";
import { useProfileStore } from "~/stores/profilePage";
import { useUserStore } from "~/stores/user";

type VerifyUser = {
  type: "link" | "code" | null;
  email: string;
  code?: string;
  hash?: string;
  expires?: string;
};

export const useAuthStore = defineStore(
  "auth",
  () => {
    const { currentUserProfileQuery } = useUser();
    const profileStore = useProfileStore();
    const userStore = useUserStore();

    const toast = useToast();
    const loggedIn = ref(false);
    const verifyingUser = reactive<VerifyUser>({
      type: null,
      email: "",
      code: "",
      hash: "",
      expires: "",
    });
    const token = useCookie("Bearer");
    const requestMagicLink = useMutation(
      graphql(`
        mutation requestMagicLink($email: String!) {
          requestLoginLink(input: { email: $email }) {
            email
            status {
              success
              identifier
              message
            }
          }
        }
      `)
    );

    const loginUsingMagicLink = useMutation(
      graphql(`
        mutation verifyLoginLink(
          $email: String!
          $expires: Int!
          $hash: String!
        ) {
          verifyLoginLink(
            input: { email: $email, expires: $expires, hash: $hash }
          ) {
            isVerified
            accessToken
            status {
              success
              identifier
              message
            }
          }
        }
      `)
    );

    const loginUsingMagicLinkCode = useMutation(
      graphql(`
        mutation VerifyLoginCode($email: String!, $code: String!) {
          verifyLoginCode(input: { email: $email, code: $code }) {
            isVerified
            accessToken
            status {
              success
              identifier
              message
            }
          }
        }
      `)
    );

    const loginUsingAccount = useMutation(
      graphql(`
        mutation loginWithAccount($username: String!, $password: String!) {
          login(input: { username: $username, password: $password }) {
            accessToken
            status {
              success
              identifier
              message
            }
          }
        }
      `)
    );

    const logout = useMutation(
      graphql(`
        mutation logout {
          logout {
            status {
              message
              success
            }
          }
        }
      `),
      {
        errorPolicy: "all",
      }
    );

    const onLogout = (error?: undefined | ApolloError) => {
      if (error) console.error("Logout error", error);

      toast.success("Successfully logged out");
      token.value = undefined;
      loggedIn.value = false;

      profileStore.flush();
      userStore.flush();
    };

    const onLoginError = (
      error: Error | ApolloError,
      context: Context | undefined,
      useMessage = false
    ) => {
      toast.error(
        `Login failed: ${useMessage ? error.message : "something unexpected happened"}`
      );

      if (!useMessage) console.error("Login error", error, context);

      loggedIn.value = false;
      token.value = undefined;

      profileStore.flush();
      userStore.flush();
    };

    const onLogin = (
      accessToken: string | undefined | null,
      status: Status | undefined,
      showWelcomeToast = true
    ) => {
      const { load, refetch } = currentUserProfileQuery;

      if (!status?.success && status?.identifier === "banned")
        return onLoginError(new Error("You are banned :("), undefined, true);

      if (!status?.success)
        return onLoginError(new Error("Invalid credentials"), undefined, true);

      token.value = accessToken;
      loggedIn.value = !!token.value;

      if (showWelcomeToast) toast.success("Welcome back!");

      if (loggedIn.value) load() || refetch();
    };

    const loginWithAccessToken = (accessToken: string) =>
      onLogin(accessToken, undefined);

    loginUsingMagicLink.onDone((result) => {
      const isVerified = result?.data?.verifyLoginLink.isVerified;
      const accessToken = result?.data?.verifyLoginLink.accessToken;

      if (!isVerified) {
        return (verifyingUser.type = "link");
      }
      if (result.data?.verifyLoginLink.status)
        onLogin(
          result?.data?.verifyLoginLink.accessToken,
          result.data?.verifyLoginLink.status
        );
      if (result.data?.verifyLoginLink.status)
        return onLogin(accessToken, result.data?.verifyLoginLink.status);
    });

    loginUsingAccount.onDone((result) => {
      if (result.data?.login?.status) {
        onLogin(result?.data?.login.accessToken, result.data?.login.status);
      }
    });

    loginUsingMagicLinkCode.onDone((result) => {
      const isVerified = result?.data?.verifyLoginCode.isVerified;
      const accessToken = result?.data?.verifyLoginCode.accessToken;

      if (!isVerified) {
        return (verifyingUser.type = "code");
      }
      if (result.data?.verifyLoginCode.status)
        return onLogin(accessToken, result.data?.verifyLoginCode.status);
    });

    loginUsingAccount.onError(onLoginError);
    loginUsingMagicLink.onError(onLoginError);
    loginUsingMagicLinkCode.onError(onLoginError);

    logout.onDone(() => onLogout());
    logout.onError(onLogout);

    const resetVerificationState = () => {
      verifyingUser.type = null;
      verifyingUser.email = "";
      verifyingUser.code = "";
      verifyingUser.hash = "";
      verifyingUser.expires = "";
    };

    const verifyUserMutation = useMutation(verifyUser);

    verifyUserMutation.onDone((result) => {
      const status = result.data?.verifyUser.status;

      if (status?.success) {
        toast.success("Account created successfully");
        resetVerificationState();

        onLogin(result.data?.verifyUser.accessToken, status, false);
      } else {
        toast.error("Something went wrong. Please try again.");
        resetVerificationState();
      }
    });

    verifyUserMutation.onError(() => {
      toast.error("Error on creating account");
      resetVerificationState();
    });

    const reset = () => {
      loggedIn.value = false;
      verifyingUser.type = null;
      verifyingUser.email = "";
      verifyingUser.code = "";
      verifyingUser.hash = "";
      verifyingUser.expires = "";
      token.value = undefined;
    };

    return {
      loggedIn,
      verifyingUser,

      // Methods
      requestMagicLink,
      loginUsingAccount,
      loginUsingMagicLink,
      loginUsingMagicLinkCode,
      loginWithAccessToken,
      logout,
      verifyUserMutation,
      reset,
    };
  },
  {
    persist: true,
  }
);
