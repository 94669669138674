import { defineStore } from "pinia";
import type { Challenge } from "~/gql/graphql";

type State = {
  challenge: null | undefined | Challenge;
  activeChallenges: null | undefined | Challenge[];
  activeChallengeCount: null | undefined | number;
  openChallenges: null | undefined | Challenge[];
  openChallengeCount: null | undefined | number;
};

// https://pinia.vuejs.org/core-concepts/#Setup-Stores
export const useChallengeDetailsStore = defineStore("challengeDetails", () => {
  const state = reactive<State>({
    challenge: null,
    activeChallenges: null,
    openChallenges: null,
    activeChallengeCount: 0,
    openChallengeCount: 0,
  });

  const reset = () => {
    state.challenge = null;
  };

  return {
    reset,
    ...toRefs(state),
  };
});
