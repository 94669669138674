import { useLazyQuery } from "@vue/apollo-composable";
import { getCurrentUserProfileQuery } from "~/graphql/user";
import { useUserStore } from "~/stores/user";

export default () => {
  const userStore = useUserStore();

  // When switching accounts, this call should not use cache, otherwise we'll see the previous user's data
  const currentUserProfileQuery = useLazyQuery(
    getCurrentUserProfileQuery,
    {},
    {
      fetchPolicy: "no-cache",
    }
  );

  currentUserProfileQuery.onResult((result) => {
    if (result.loading || !result.data?.getCurrentUserProfile?.profile) return;
    userStore.update(result.data.getCurrentUserProfile?.profile);
  });

  return {
    userStore,

    // Apollo
    currentUserProfileQuery,
  };
};
