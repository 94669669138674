import { defineStore } from "pinia";
import type { OpenPayoutAmountPayload, WalletPayload } from "~/gql/graphql";

export const useWalletStore = defineStore("walletStore", () => {
  const createDefaults = () =>
    <Partial<WalletPayload & OpenPayoutAmountPayload>>{
      // TODO: camelCase graphql response
      wallet: {
        availableAmount: 0,
        freezeAmount: 0,
        totalAmount: 0,
      },
      amount: 0,
    };

  const state = reactive(createDefaults());

  const update = (newState: Partial<WalletPayload | OpenPayoutAmountPayload>) =>
    Object.assign(state, newState);

  const flush = () => Object.assign(state, createDefaults());

  return {
    ...toRefs(state),

    // Methods
    update,
    flush,
  };
});
