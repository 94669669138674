import { defineStore } from "pinia";

type State = {
  name: string | undefined;
  description: string | undefined;
  amount: number;
  hashTags: string[];
  expirationDate: Date | undefined;
  visibility: "public" | "private";
  challengedUserEmailsString: string | undefined;
  challengedUsername: string | undefined;
  timestamp: number;
};

// https://pinia.vuejs.org/core-concepts/#Setup-Stores
export const useCreateChallengeStore = defineStore(
  "createChallengeStore",
  () => {
    const createDefaults = (): State => ({
      name: "",
      description: "",
      amount: 5,
      hashTags: [],
      expirationDate: undefined,
      visibility: "public",
      challengedUserEmailsString: undefined,
      challengedUsername: undefined,
      timestamp: Date.now(),
    });

    const state = reactive<State>(createDefaults());

    const flush = () => Object.assign(state, createDefaults());

    return {
      ...toRefs(state),

      // Methods
      flush,
    };
  },
  {
    persist: true,
  }
);
